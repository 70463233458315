@import 'scss/variables.scss';

.hrd_list{
    a, a:hover{
        text-decoration: none;
        color: inherit;
    }

}

.member_list{
    .plain_list > li{
        border-bottom: 1px solid $lm-gray-300;
    }

    .column_profile{

    }

    .column_role{
        width: 74px;
    }

    .column_status{
        width: 120px;
    }

}