@import './variables.scss';

// Override Variables
// https://getbootstrap.com/docs/4.1/getting-started/theming/
$font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;
$body-color: $lm-gray-700 !default;


@import '~bootstrap/scss/bootstrap.scss';

// Use Mixins
.btn-brand {
    @include button-variant($lm-brand, $lm-brand);
}

.btn-outline-brand {
    @include button-outline-variant($lm-brand);
}