
%plain_list{
	-webkit-padding-start: 0;
	list-style: none;
	padding-left: 0;
}

.plain_list{
    @extend %plain_list;
}

.inline_list{
    @extend %plain_list;
    & > li{
        display: inline-block;
        margin-right: 5px;
                
        &:last-child{
            margin-right: 0 !important;
        }    
    }
}