
$brand-facebook: #3a5795;
$brand_kakao_yello: #FFE200;
$brand_kakao_brown: #3d1d20;

$lm-body-background: #fff;
$lm-white: #fff;
$lm-black: #000;


$font-size-h1: 112px;	//           floor(($font-size-base * 2.6)); // ~36px
$font-size-h2: 56px;	//           floor(($font-size-base * 2.15)); // ~30px
$font-size-h3: 45px;	//           ceil(($font-size-base * 1.7)); // ~24px
$font-size-h4: 34px;	//           ceil(($font-size-base * 1.25)); // ~18px
$font-size-h5: 24px;	//           $font-size-base;
$font-size-h6: 20px;	//           ceil(($font-size-base * 0.85)); // ~12px
$font-size-h7: 18px;	//           for mobile device reduce


$lm-gray-900: #212121;
$lm-gray-850: #303030;
$lm-gray-800: #424242;
$lm-gray-700: #616161;
$lm-gray-600: #757575;
$lm-gray-500: #9E9E9E;
$lm-gray-400: #BDBDBD;
$lm-gray-300: #e0e0e0;
$lm-gray-200: #EEEEEE;
$lm-gray-100: #f5f5f5;
$lm-gray-50: #fafafa;
$lm-gray: $lm-gray-700;     
// base

$lm-brand-50: #EBEAF5;
$lm-brand-100: #CAC7E4;
$lm-brand-200: #AAA3D0;
$lm-brand-300: #847EB9;
$lm-brand-400: #6A63AC;
$lm-brand-500: #5652A1;
$lm-brand-700: #464097;
$lm-brand: $lm-brand-400;   
// base