@import 'scss/variables.scss';

.hr_utilty_bar{
    border-top: 1px solid $lm-gray-200;
    background-color: $lm-gray-50;
}

.hr_list{
    font-size: 0.8em;
    border-bottom: 1px solid $lm-gray-200;

    .lm_profile_hr{
        img{
            width: 50px;
            border-radius: 50%;
        }

        a, a:hover{
            text-decoration: none;
            color: inherit;
        }
    }

    .table{
        td{
            vertical-align: middle;
        }
    }
}