@import 'scss/variables.scss';
@import 'scss/flex.scss';
@import 'scss/base.scss';

#app_wrapper {
  
  & > .container-fluid{
    padding: 0 45px;
  }

  &.layout_vertical_full{
    @include flexbox;
    height: 100vh;
  }
}

#desk_global_nav{
  background-color: $lm-gray-50;
  height: 65px;
  border-bottom: 1px solid $lm-gray-600;
  @include flexbox;
  @include flex-direction(row);
  @include align-items(center);

  .flex_spacer{
    @include flex(1);
  }

  .brand_logo{
    width: 125px;
    height: 27.3px;
    object-fit: contain;
    vertical-align: bottom;
    margin-right: 12px;
  }

  .vertical_title{
    display: inline;
    color: $lm-gray;
    font-size: $font-size-h5;
  }
}

.local_nav{
  border-bottom: 1px solid $lm-gray-400;

  a.nav-link{
    color: $lm-gray;

    &.active{
      color: $lm-brand;
    }
  }
}