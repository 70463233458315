#app_splash{
    height: 100vh;

}

.loading_indct {
    text-align: center;
    img{
        max-width: 110px;
    }
}